import React from 'react'
import '../style/Shop.css'
import amazon from '../resources/images/amazon_logo.png'

function Shop() {
    return (
        <div className='shop'>
            <div className='shop__panel'>
                <div className='shop__content'>
                    <div className='shop__headline'>
                        <p>Shop</p>
                    </div>
                    <div className='shop__text'>
                        <h3>Coming soon on Amazon</h3>
                        {/* <p>Purchase our bedsheets on Amazon.</p> */}
                    </div>
                </div>
                <img
                    src={amazon}
                    alt=''
                />
            </div>
        </div>
    )
}

export default Shop
